.cards {
    display: grid;
    grid-template-columns: 1fr;
    font-family: "PT Mono", sans-serif;
}

.card {
    flex: 1;
    border: 1px solid #393939;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 20px;
}

.card__menu-item {
    color: #393939;
}

.card__divider {
    border-top: 1px solid #e3e2e2;
    width: 40%;
    align-self: center;
}
